import urlJoin from 'url-join';
import { OPS_API_URL } from '../../../const';
import { executeRequest } from '../../utils';

export type ShippingInfoResponse = {
    success: boolean;
    status_code: number;
    error?: string;
    errorCode?: string;
    ship_to_customer_fee: number /** @deprecated */;
    ship_to_reseller_fee: number /** @deprecated */;
    ship_to_reseller_workdays: number /** @deprecated */;
    ship_to_customer_workdays: number /** @deprecated */;
    has_delay: boolean;
    price_to_reseller_free_shipping: number /** @deprecated */;
    price_to_customer_free_shipping: number /** @deprecated */;
    vendors: {
        fee: number;
        workdays: number;
        price_to_free_shipping: number;
        vendor_id: string;
    }[];
};

export interface Dimensions {
    length: number;
    width: number;
    height: number;
}

export type ShippingInfoRequest = {
    reseller_id: string;
    postal_code?: string;
    auto_checkout: boolean;
    items: ShipmentInfoItemRequest[];
};

export type ShipmentInfoItemRequest = {
    quantity: number;
    variant_id: string;
};

export async function getShippingInfo(request: ShippingInfoRequest): Promise<ShippingInfoResponse> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'orders', 'shipment', 'simulate'),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        },
        {
            ignoreStatus: true,
        }
    );
}
